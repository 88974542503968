import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout/Layout';

const NotFoundPage = () => {

  return (
    <Layout>
      
      <div className='col-lg-9 col-md-12 mt-6'>
        <h1>Cette page n'existe pas</h1>
        <div className='mt-6'> 
          <Link to={'/'} style={{textDecoration: 'underline', color: 'black'}}>
            Retournez vers la page d'accueil
          </Link>
        </div>
        
      </div>
    </Layout>
  );
};

export default NotFoundPage;
